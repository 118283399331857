import React, { useState, useEffect } from "react";
import { FaChevronCircleUp } from "react-icons/fa";
import "./ScrollTop.scss";

const ScrollTop = () => {
    const [showScroll, setShowScroll] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", checkScrollTop);
        return function cleanup() {
            window.removeEventListener("scroll", checkScrollTop);
        };
    });

    const checkScrollTop = () => {
        if (!showScroll && window.pageYOffset > 400) {
            setShowScroll(true);
        } else if (showScroll && window.pageYOffset <= 400) {
            setShowScroll(false);
        }
    };

    const scrollTop = () => {
        window.scrollTo({ top: 0 });
    };

    return (
        <FaChevronCircleUp
            className="scrollTop non-mobile"
            onClick={scrollTop}
            style={{ height: 40, display: showScroll ? "inline" : "none" }}
        />
    );
};

export default ScrollTop;
