import React, { useState } from "react";
import { Link } from 'react-router-dom'
import { Dropdown } from 'antd'
import { Row, Col, Menu, Drawer, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { DownOutlined } from '@ant-design/icons';
import { FiMenu } from 'react-icons/fi';
import { setLanguage } from '../../store/actions/langActions';
import { translate } from '../../i18n';
import LogoDanixt from '../../images/LogoDanixt.png';
import LogoDanixt2 from '../../images/LogoDanixt2.png';
import useWindowDimensions from '../../hooks/useWindowDimensions';

import "./Header.scss";

const { SubMenu } = Menu;

const Header = () => {
  const isMobile = useWindowDimensions().width <= 740;
  const { language } = useSelector((state) => state.lang);
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);

  const chhoseLanguageHandler = value => {
    dispatch(setLanguage(value));
  }

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const MenuContent = ({ modeValue, classNameValue, subEltClassName, eltClassName }) => {
    return (
      <Menu defaultSelectedKeys={window.location.pathname.replace("/", "")} forceSubMenuRender={true} mode={modeValue} className={classNameValue} >
        <Menu.Item className={eltClassName} onClick={onClose} key="home">
          <Link to="/">{translate("menu_home", language)}</Link>
        </Menu.Item>
        <SubMenu key="ressources" className={eltClassName} title={translate("menu_resources", language)}>
          <Menu.Item onClick={onClose} className={subEltClassName} key="guide-du-vendeur">
            <Link to="/guide-du-vendeur">{translate("menu_resource_seller", language)}</Link>
          </Menu.Item>
          <Menu.Item onClick={onClose} className={subEltClassName} key="boutiques-et-tarifs">
            <Link to="/boutiques-et-tarifs">{translate("menu_resource_tarif", language)}</Link>
          </Menu.Item>
          <Menu.Item onClick={onClose} className={subEltClassName} key="livraison">
            <Link to="/livraison">{translate("menu_resource_livraison", language)}</Link>
          </Menu.Item>
          <Menu.Item onClick={onClose} className={subEltClassName} key="cadeaux">
            <Link to="/cartes-cadeaux">{translate("menu_resource_giftcard", language)}</Link>
          </Menu.Item>
          <Menu.Item onClick={onClose} className={subEltClassName} key="promotions">
            <Link to="/promotions">{translate("menu_resource_promotion", language)}</Link>
          </Menu.Item>
        </SubMenu>
        <SubMenu key="policy" className={eltClassName} title={translate("menu_policy", language)}>
          <Menu.Item onClick={onClose} className={subEltClassName} key="confidentialite">
            <Link to="/confidentialite">{translate("menu_policy_privacy", language)}</Link>
          </Menu.Item>
          <Menu.Item onClick={onClose} className={subEltClassName} key="mentions-legales">
            <Link to="/mentions-legales">{translate("menu_policy_legalnotice", language)}</Link>
          </Menu.Item>
          <Menu.Item onClick={onClose} className={subEltClassName} key="retours">
            <Link to="/retours">{translate("menu_policy_return", language)}</Link>
          </Menu.Item>
          <Menu.Item onClick={onClose} className={subEltClassName} key="nos-conditions">
            <Link to="/nos-conditions">{translate("menu_policy_terms", language)}</Link>
          </Menu.Item>
        </SubMenu>
        <SubMenu key="Carrieres" className={eltClassName} title={translate("menu_career", language)}>
          <Menu.Item onClick={onClose} className={subEltClassName} key="carriere">
            <Link to="/carriere">{translate("menu_career_culture", language)}</Link>
          </Menu.Item>
          <Menu.Item onClick={onClose} className={subEltClassName} key="emplois">
            <Link to="/emplois">{translate("menu_career_jobs", language)}</Link>
          </Menu.Item>
        </SubMenu>
        <Menu.Item onClick={onClose} className={eltClassName} key="contact">
          <Link to="/contact">{translate("menu_contact", language)}</Link>
        </Menu.Item>
        <Menu.Item onClick={onClose} className={eltClassName} key="aide">
          <Link to="/aide">{translate("menu_help", language)}</Link>
        </Menu.Item>
      </Menu>
    )
  }

  const languageMenu = (
    <Menu>
      <Menu.Item onClick={() => chhoseLanguageHandler('fr')} key="0">
        Français
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item onClick={() => chhoseLanguageHandler('en')} key="1">
        English
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="header_area navbar_fixed"  >
      <div className="navbar navbar-expand-lg menu_one menu_eight">
        <div className="container2111 mt-3" style={{ width: '100%' }}>
          <Row className="header-nav-row">
            <Col xl={4} lg={4} md={18} sm={18} xs={18}>
              <Row gutter={[10, 0]} align="middle">
                <Col className="drawerMenu pt-2">
                  <Button className="barsMenu2 mb-2" style={{ paddingTop: '0px' }} size="large" type="primary" onClick={showDrawer}>
                    <FiMenu className="barsBtn2" />
                  </Button>
                  <Drawer style={{ padding: '0px' }} width={275} placement="left" closable={false} onClose={onClose} visible={visible}>
                    <div className="navbar__drawer" >
                      <div className="navbar-drawer-head">
                        <img style={{ width: '55%' }} src={LogoDanixt2} alt="Danixt" />
                      </div>
                      <div className="navbar__drawer-links" style={{ marginBottom: '2rem' }}>
                        <div className="menu__back-selector pt-3">
                          <MenuContent modeValue="inline" subEltClassName="drawer-subElement" eltClassName="drawer-element" classNameValue="" />
                        </div>
                      </div>
                    </div>
                  </Drawer>
                </Col>
                <Col xl={24} lg={24} md={16} sm={16} xs={16}>
                  <Link to="/" className="navbar-brand sticky_logo" style={{ maxWidth: '170px' }}>
                    <img src={LogoDanixt} alt="logo danixt.com" style={{ width: '70%' }} />
                    <img src={LogoDanixt} alt="logo danixt.com" style={isMobile ? { width: '80%' } : { width: '90%', marginTop: '5px' }} />
                  </Link>
                </Col>
              </Row>
            </Col>
            <Col align="end" xl={18} lg={17} md={0} sm={0} xs={0} className="text-right pt-1">
              <MenuContent modeValue="horizontal" subEltClassName="header-subElement" eltClassName="" classNameValue="navBar-main-scrolled mt-1 text-right" />
            </Col>
            <Col align="end" xl={2} lg={3} md={6} sm={6} xs={6} className="pt-2">
              <Dropdown placement="bottomCenter" overlay={languageMenu} trigger={['click']}>
                <Button size="large" type="link" style={{ fontSize: '16px', color: '#000' }} >
                  {language && language.toUpperCase()} <DownOutlined size="11" style={{ color: '#000' }} />
                </Button>
              </Dropdown>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

export default Header;