export default function validate(email) {
    let errors = {};

    const regExp = RegExp(
        /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/
    );

    if (!email) {
        errors.email_fr = 'Adresse email requise';
        errors.email_en = 'Email address required';
    }
    else if (!email.match(regExp)) {
        errors.email_fr = 'Adresse email invalide';
        errors.email_en = 'Invalid email address';
    }

    return errors;
}