import React, { useState } from 'react';
import { Row, Col, Input, Button, Alert } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux'
import { FiSend } from 'react-icons/fi';
import { translate } from '../../i18n';
import validate from '../../components/FormValidation/NewsLetterValidation';
import { saveEmail } from '../../functions/contactmessage'
import './Footer.scss'

const NewsLetters = () => {
    const { language } = useSelector((state) => state.lang);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorEmail, setErrorEmail] = useState({});
    const [errorMessage, setErrorMessage] = useState({});
    const [email, setEmail] = useState("");

    const successMessage = {
        message_fr: "Votre adresse email a été enregistrée avec succès. Merci !",
        message_en: "Your email address has been registered successfully. Thanks !"
    }

    const handleChange = (e) => {
        setEmail(e.target.value);
        setError(false)
        setSuccess(false)
        setErrorMessage({ ...errorMessage, message_fr: '', message_en: '' })
        setErrorEmail({ ...errorMessage, email_fr: '', email_en: '' })
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setErrorEmail(validate(email));
        if (validate(email).email_fr) {
            return;
        } else {
            try {
                setLoading(true)
                saveEmail({ email })
                    .then((res) => {
                        setSuccess(true)
                        setError(false)
                        setLoading(false)
                    })
                    .catch((error) => {
                        setErrorMessage(error.response.data)
                        setSuccess(false)
                        setError(true)
                        setLoading(false)
                    })
            } catch (error) {
                setErrorMessage(error.response.data)
                setSuccess(false)
                setError(true)
                setLoading(false)
            }
        }
    };


    return (
        <div className="pb-5 mb-5 pt-5" style={{ borderTop: '0px solid var(--main-grey)' }}>
            <div className="sec_title1 text-center mb-0 wow fadeInUp" >
                <h2 className="f_p f_size_30 l_height20 f_600 t_color">{translate("newsTitle", language)}</h2>
                <p className="pl-4 pr-4 " style={{ color: 'var(--main-dark)', lineHeight: '20px' }}>{translate("newsDescription", language)}</p>
            </div>
            <form className="mailchimp" onSubmit={handleSubmit} noValidate>
                <div className="text-center pt-4 col-md-6 offset-md-3">
                    <Input.Group>
                        <Row>
                            {
                                success && <Col span={24} className="pb-2 text-left">
                                    <Alert message={successMessage['message_' + language]} type="success" showIcon />
                                </Col>
                            }
                            {
                                error && <Col span={24} className="pb-2 text-left">
                                    <Alert message={errorMessage['message_' + language]} type="error" showIcon />
                                </Col>
                            }
                            <Col span={16}>
                                <Input type="email" name="email" value={email} placeholder={translate("newsPlaceholder", language)} onChange={handleChange}
                                    style={{
                                        width: '100%',
                                        height: '55px',
                                        borderRight: '0px',
                                        borderTopRightRadius: '0px',
                                        borderBottomRightRadius: '0px',
                                        fontSize: '17px',
                                        fontWeight: 'normal'
                                    }}
                                />
                                {errorEmail.email_fr && <div className="contact_input_error">{errorEmail['email_' + language]}</div>}
                            </Col>
                            <Col span={8}>
                                <Button type="primary" htmlType="submit" disabled={success}
                                    style={{
                                        width: '100%',
                                        height: '55px',
                                        fontSize: '18px',
                                        boxShadow: 'rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px'
                                    }}
                                >
                                    {loading ? <LoadingOutlined style={{ color: '#fff' }} className="mr-3" /> : <FiSend className="mr-3" />}{translate("contact_form_btn_label", language)}
                                </Button>
                            </Col>
                        </Row>

                    </Input.Group>

                </div>
            </form>
        </div>
    )
}

export default NewsLetters;