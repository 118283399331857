import React from "react";
import { Row, Col, Select, Avatar } from "antd";
import { GlobalOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaYoutube, FaInstagram } from 'react-icons/fa'
import { setLanguage } from '../../store/actions/langActions';
import NewsLetters from './NewsLetters';
import "./Footer.scss";
import Logo from '../../images/LogoDanixt2.png';
import visa from '../../images/paymentIcons/visa.png'
import wave from '../../images/paymentIcons/wave.png'
import mtn from '../../images/paymentIcons/mtnCard.png'
import orange from '../../images/paymentIcons/orangeCard.png'
import mastercard from '../../images/paymentIcons/mastercard.png'
import maestro from '../../images/paymentIcons/maestro.png'

const { Option } = Select;

const marginRight = {
  marginRight: 30
}

const Footer = () => {
  const { language } = useSelector((state) => state.lang);
  const dispatch = useDispatch();

  const chhoseLanguageHandler = value => {
    dispatch(setLanguage(value));
  }


  return (
    <>
      <NewsLetters />
      <div className="footer" style={{ borderTop: '1px solid #222' }}>
        <div className="footer__country-payment">
          <Row justify="space-around" className="text-center" gutter={[15, 25]}>
            <Col xl={8} lg={8} md={12} sm={24} xs={24}>
              <Row>
                <Col span={12} className="text-center">
                  <img src={Logo} alt="logo" height="30" className="footerLogo" style={marginRight} />
                </Col>
                <Col span={12}>
                  <Select
                    value={language}
                    className="footer__select"
                    clearIcon={<></>}
                    style={marginRight}
                    onChange={chhoseLanguageHandler}
                  >
                    <Option value="en">
                      <Row align="middle" gutter={6}>
                        <Col className="d-inline-flex d-inline-flex-center">
                          <GlobalOutlined style={{ color: '#febd69' }} />
                        </Col>
                        <Col>English</Col>
                      </Row>
                    </Option>
                    <Option value="fr">
                      <Row align="middle" gutter={6}>
                        <Col className="d-inline-flex d-inline-flex-center">
                          <GlobalOutlined style={{ color: '#febd69' }} />
                        </Col>
                        <Col>Français</Col>
                      </Row>
                    </Option>
                  </Select>
                </Col>
              </Row>
            </Col>
            <Col xl={8} lg={8} md={12} sm={24} xs={24} className="text-center">
              <img src={visa} className="footer__country-payment-cpimg" alt="Visa Card" />
              <img src={mastercard} className="footer__country-payment-cpimg" alt="Master Card" />
              <img src={maestro} className="footer__country-payment-cpimg" alt="Maestro Card" />
              <img src={wave} className="footer__country-payment-cpimg" alt="Wave" />
              <img src={orange} className="footer__country-payment-cpimg" alt="Orange Mobile Money" />
              <img src={mtn} className="footer__country-payment-cpimg" alt="MTN Mobile Money" />
            </Col>
            <Col xl={8} lg={8} md={12} sm={24} xs={24}>
              <Row justify="center" gutter={[15, 0]} align="middle" className="mb-2">
                <Col >
                  <a href="https://www.facebook.com/danixtgroup" target="_blank" rel="noreferrer">
                    <Avatar className="social-avatar social-avatar-facebook" size={30}  >
                      <FaFacebookF />
                    </Avatar>
                  </a>
                </Col>
                <Col >
                  <a href="https://twitter.com/danixtgroup" target="_blank" rel="noreferrer">
                    <Avatar className="social-avatar social-avatar-twitter" size={30}  >
                      <FaTwitter />
                    </Avatar>
                  </a>
                </Col>
                <Col>
                  <a href="https://www.linkedin.com/company/danixtgroup" target="_blank" rel="noreferrer">
                    <Avatar className="social-avatar social-avatar-linkedin" size={30}  >
                      <FaLinkedinIn />
                    </Avatar>
                  </a>
                </Col>
                <Col>
                  <a href="https://www.youtube.com/@danixtgroup" target="_blank" rel="noreferrer">
                    <Avatar className="social-avatar social-avatar-youtube" size={30}  >
                      <FaYoutube />
                    </Avatar>
                  </a>
                </Col>
                <Col>
                  <a href="https://www.instagram.com/danixtgroup" target="_blank" rel="noreferrer">
                    <Avatar className="social-avatar social-avatar-instagram" size={30}  >
                      <FaInstagram />
                    </Avatar>
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>

        </div>
        <div className="footer__copyright">
          <Row align="center" gutter={18}>
            <Col>
              <Link to="" className="footer__copyright-copy">
                &copy; 2023 - Danixt Group
              </Link>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default Footer;
