import React from "react";
import { Switch, Route } from "react-router-dom";
import Loadable from 'react-loadable';
import Header from './components/Header';
import Footer from './components/Footer';
import { GeneralLoading } from './components/General/LoadingSpinner';
import ScrollTop from './components/General/ScrollTop';

const Home = Loadable({
    loader: () => import('./pages/Home'),
    loading: GeneralLoading
})

/* Ressources */
const SellerGuide = Loadable({
    loader: () => import('./pages/Documentation/SellerGuide'),
    loading: GeneralLoading
})
const Tarifs = Loadable({
    loader: () => import('./pages/Documentation/Tarifs'),
    loading: GeneralLoading
})
const Points = Loadable({
    loader: () => import('./pages/Documentation/Points'),
    loading: GeneralLoading
})
const GiftCard = Loadable({
    loader: () => import('./pages/Documentation/GiftCard'),
    loading: GeneralLoading
})
const Promotions = Loadable({
    loader: () => import('./pages/Documentation/Promotions'),
    loading: GeneralLoading
})

/* POLICIES */
const Privacy = Loadable({
    loader: () => import('./pages/Documentation/Privacy'),
    loading: GeneralLoading
})
const LegalNotice = Loadable({
    loader: () => import('./pages/Documentation/LegalNotice'),
    loading: GeneralLoading
})
const Terms = Loadable({
    loader: () => import('./pages/Documentation/Terms'),
    loading: GeneralLoading
})
const ReturnPolicy = Loadable({
    loader: () => import('./pages/Documentation/ReturnPolicy'),
    loading: GeneralLoading
})

/* Careers */
const Culture = Loadable({
    loader: () => import('./pages/Careers/Culture'),
    loading: GeneralLoading
})
const JobsList = Loadable({
    loader: () => import('./pages/Careers/JobsList'),
    loading: GeneralLoading
})
/* const JobDetails = Loadable({
    loader: () => import('./pages/Careers/JobDetails'),
    loading: GeneralLoading
})
const ApplyJob = Loadable({
    loader: () => import('./pages/Careers/ApplyJob'),
    loading: GeneralLoading
}) */
// -- HELP --
const Faq = Loadable({
    loader: () => import('./pages/HelpCenter/Faq'),
    loading: GeneralLoading
})
const SingleHelp = Loadable({
    loader: () => import('./pages/HelpCenter/SingleHelp'),
    loading: GeneralLoading
})

/* Contact */
const Contact = Loadable({
    loader: () => import('./pages/Contact'),
    loading: GeneralLoading
})
/* 404 */
const FourZeroFour = Loadable({
    loader: () => import('./pages/FourZeroFour'),
    loading: GeneralLoading
})

const App = () => {

    return (
        <>
            <Header />
            <Switch>
                <Route exact path="/" component={Home} />
                {/* Ressources */}
                <Route exact path="/guide-du-vendeur" component={SellerGuide} />
                <Route exact path="/boutiques-et-tarifs" component={Tarifs} />
                <Route exact path="/livraison" component={Points} />
                <Route exact path="/cartes-cadeaux" component={GiftCard} />
                <Route exact path="/promotions" component={Promotions} />
                {/* Ressources */}


                {/* Policies */}
                <Route exact path="/confidentialite" component={Privacy} />
                <Route exact path="/mentions-legales" component={LegalNotice} />
                <Route exact path="/retours" component={ReturnPolicy} />
                <Route exact path="/nos-conditions" component={Terms} />
                {/* Policies */}

                {/* Careers */}
                <Route exact path="/carriere" component={Culture} />
                <Route exact path="/emplois" component={JobsList} />
                {/* <Route exact path="/emploi/1" component={JobDetails} />
                <Route exact path="/candidature/1" component={ApplyJob} /> */}
                {/* Contact */}
                <Route exact path="/contact" component={Contact} />
                {/* -- HELP -- */}
                <Route exact path="/aide" component={Faq} />
                <Route exact path="/help" component={Faq} />
                <Route exact path="/faq" component={Faq} />
                <Route exact path="/help/:slug" component={SingleHelp} />
                <Route exact path="/aide/:slug" component={SingleHelp} />
                <Route exact path="/faq/:slug" component={SingleHelp} />

                {/* --404 -- */}
                <Route component={FourZeroFour} />
            </Switch>
            <ScrollTop />
            <Footer />
        </>
    );
}

export default App;
